import React, { useEffect, useState } from 'react';
import { HexAlphaColorPicker } from 'react-colorful';
import './index.css';
import CommonButton from 'components/CommonButton/CommonButton';
import DefaultModal from 'components/Modals/DefaultModal';
import useModal from 'hooks/useModal';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar-widget.svg';

import { Popover } from '@headlessui/react';
import PreviewColorPicker from '../PreviewColorPicker/PreviewColorPicker';
import useWidgetGenerate from 'hooks/useWidgetGenerate';
import useWidget from 'hooks/useWidget';
import axiosInstance from 'apis/axiosInstance';
import { renderToast } from 'components/Toast/Toast';
import { loadSelectedClinicId } from 'utils/storage';

const CustomizeScheduleButton = () => {
  const { data } = useWidgetGenerate();
  const { data: widgetData, isLoading, mutate } = useWidget(data?.widgetKey);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [color, setColor] = useState({
    firstColor: '',
    secondColor: '',
    textColor: '',
  });

  const onClose = () => {
    setIsModalOpen(false);
    setColor(widgetData!.clinic.metadata);
  };

  const { setIsAllowed } = useModal({ onClose: onClose });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setColor({ ...color, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (!widgetData) return;
    setColor(widgetData.clinic.metadata);
  }, [widgetData]);

  const onUpdate = async () => {
    setIsSubmitting(true);
    setIsAllowed(false);

    const payload = {
      ...color,
      clinicId: loadSelectedClinicId(),
    };
    try {
      await axiosInstance.put('/widget/customize-style', payload);

      await mutate();
      renderToast({
        type: 'success',
        message: 'Widget styles have been updated successfully.',
      });

      setIsSubmitting(false);
      setIsAllowed(true);
      setIsModalOpen(false);
    } catch (error) {
      setIsSubmitting(false);
      setIsAllowed(true);

      renderToast({
        type: 'error',
        message: 'Something went wrong. Please try again later',
      });
    }
  };

  return (
    <>
      {isModalOpen && (
        <DefaultModal>
          <div className="p-3 min-w-[50rem] bg-white rounded-[1.6rem] text-darkest-grey text-14 leading-[2.1rem]">
            <h3 className="font-bold text-20 leading-[3rem]">
              Scheduling Button Customization
            </h3>
            <div className="mt-1">
              <section>
                <p className="text-16 leading-[2.4] font-bold">Preview</p>
                <div className="min-w-[44rem] min-h-[20rem] flex justify-center items-center">
                  <button
                    className="w-14 h-14 rounded-tl-[3rem] rounded-bl-[3rem] flex flex-col items-center justify-center"
                    style={{
                      background: color.secondColor
                        ? `linear-gradient(225deg, ${color.firstColor} 0%, ${color.secondColor} 100%)`
                        : color.firstColor,
                      color: color.textColor,
                    }}
                  >
                    <CalendarIcon className="all-child:fill-current all-child:all-child:fill-current w-3.6 h-3.6 mb-1" />
                    <span className="text-18 leading-[2.7rem] font-poppins font-[300] ">
                      Schedule
                    </span>
                    <span className="text-22 leading-[3rem] uppercase font-poppins font-[600]">
                      online
                    </span>
                  </button>
                </div>
              </section>
              <section className="mt-1.6">
                <p className="text-16 leading-[2.4] font-bold">Colors</p>
                <div className="flex justify-between items-start all-child:w-[21.6rem]">
                  <PreviewColorPicker
                    name="firstColor"
                    value={color.firstColor}
                    onChange={onChange}
                    panel={
                      <Popover.Panel className="absolute -top-1 left-[21.6rem] z-10">
                        <div
                          data-color-picker={true}
                          className="p-1.2 bg-white shadow-primary rounded-[0.8rem]"
                        >
                          <HexAlphaColorPicker
                            color={color.firstColor}
                            onChange={(newValue) =>
                              setColor({ ...color, firstColor: newValue })
                            }
                          />
                        </div>
                      </Popover.Panel>
                    }
                  />
                  <PreviewColorPicker
                    name="secondColor"
                    value={color.secondColor}
                    onChange={onChange}
                    panel={
                      <Popover.Panel className="absolute -top-1 right-[21.6rem] z-10">
                        <div
                          data-color-picker={true}
                          className="p-1.2 bg-white shadow-primary rounded-[0.8rem]"
                        >
                          <HexAlphaColorPicker
                            color={color.secondColor}
                            onChange={(newValue) => {
                              const isValueInvalid = newValue.includes(
                                `${NaN}`
                              );
                              if (isValueInvalid) {
                                return;
                              }
                              setColor({ ...color, secondColor: newValue });
                            }}
                          />
                        </div>
                      </Popover.Panel>
                    }
                    children={
                      <button
                        className={`w-2.4 h-2.4 py-0.3 rounded-full text-white bg-[#727272] text-18 absolute right-0 top-0 -translate-y-1/2 translate-x-1/2 ${
                          color.secondColor ? 'visible' : 'invisible'
                        }`}
                        onClick={() => setColor({ ...color, secondColor: '' })}
                      >
                        &#x2715;
                      </button>
                    }
                  />
                </div>
                <div className="mt-1.6">
                  <p className="text-16 leading-[2.4] font-bold">Text Color</p>
                  <PreviewColorPicker
                    name={'textColor'}
                    value={color.textColor}
                    onChange={onChange}
                    panel={
                      <Popover.Panel className="absolute -top-1 left-[21.6rem] z-10">
                        <div
                          data-color-picker={true}
                          className="p-1.2 bg-white shadow-primary rounded-[0.8rem]"
                        >
                          <HexAlphaColorPicker
                            color={color.textColor}
                            onChange={(newValue) =>
                              setColor({ ...color, textColor: newValue })
                            }
                          />
                        </div>
                      </Popover.Panel>
                    }
                  />
                </div>
              </section>
              <div className="flex justify-end gap-x-1.6 mt-2.4">
                <CommonButton
                  disabled={isSubmitting}
                  variant="secondary"
                  onClick={onClose}
                >
                  Cancel
                </CommonButton>
                <CommonButton isLoading={isSubmitting} onClick={onUpdate}>
                  Update
                </CommonButton>
              </div>
            </div>
          </div>
        </DefaultModal>
      )}
      <CommonButton
        variant="secondary"
        className="!min-h-[3.2rem]"
        onClick={() => setIsModalOpen(true)}
        isLoading={isLoading}
      >
        Customize
      </CommonButton>
    </>
  );
};

export default CustomizeScheduleButton;
