import { FC } from 'react';
import { Popover } from '@headlessui/react';
import { ReactComponent as AddIcon } from 'assets/icons/add_circle_outline.svg';

interface PreviewColorPickerProps {
  name: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  panel: React.ReactNode;
}

const PreviewColorPicker: FC<PreviewColorPickerProps> = ({
  name,
  value,
  onChange,
  panel,
  children,
}) => {
  return (
    <div className="rounded-[1.6rem] p-1 bg-white shadow-primary flex flex-col gap-y-0.6 relative">
      <Popover className="relative">
        <Popover.Button
          className="w-full h-[8rem] rounded-[1rem] border border-lightest-grey flex items-center justify-center"
          style={{ background: value }}
        >
          {!value && children && (
            <div>
              <AddIcon className="all-child:all-child:fill-dim-grey" />
            </div>
          )}
        </Popover.Button>
        {panel}
      </Popover>

      {value ? (
        <input
          name={name}
          className="w-full rounded-[1rem] border-light-grey px-1.6 py-0.8 text-14 leading-[2.1rem] focus:border-light-grey hover:border-light-grey"
          type="text"
          value={value}
          onChange={onChange}
        />
      ) : children ? null : (
        <input
          name={name}
          className="w-full rounded-[1rem] border-light-grey px-1.6 py-0.8 text-14 leading-[2.1rem] focus:border-light-grey hover:border-light-grey"
          type="text"
          value={value}
          onChange={onChange}
        />
      )}
      {children}
    </div>
  );
};

export default PreviewColorPicker;
