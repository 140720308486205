import { FC, useEffect, useRef } from 'react';
import { Transition } from '@headlessui/react';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

interface DrawerProps {
  title: string;
  isShowing: boolean;
  setIsShowing: React.Dispatch<React.SetStateAction<boolean>>;
  footer?: React.ReactNode;
  enabledClickOutsideClosed?: boolean;
  onReset?: () => void;
}

const Drawer: FC<DrawerProps> = ({
  title,
  isShowing,
  setIsShowing,
  children,
  footer,
  enabledClickOutsideClosed,
  onReset,
}) => {
  const drawerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const isWebsiteOverflow =
      document.documentElement.scrollHeight >
      document.documentElement.clientHeight;

    if (!isWebsiteOverflow) return;

    document.body.style.overflow = isShowing ? 'hidden' : 'unset';
    document.body.style.paddingRight = isShowing ? '16px' : '0px';
  }, [isShowing]);

  useEffect(() => {
    if (!enabledClickOutsideClosed) return;

    const handleClickOutside = (event: MouseEvent) => {
      const drawer = drawerRef.current;
      if (!drawer) return;
      if (!drawer.contains(event.target as Node)) {
        setIsShowing(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [enabledClickOutsideClosed, setIsShowing]);

  const onClose = () => {
    setIsShowing(false);
    onReset &&
      setTimeout(() => {
        onReset();
      }, 300);
  };

  return (
    <Transition
      show={isShowing}
      enter="transition-all duration-300"
      enterFrom="-right-[42rem]"
      enterTo="right-0"
      leave="transition-all duration-300"
      leaveFrom="right-0"
      leaveTo="-right-[42rem]"
      className="fixed z-10 top-6 right-0 w-42 h-[calc(100%-6rem)] bg-white shadow-elevation-07 overflow-auto"
    >
      <div ref={drawerRef}>
        <section className="flex justify-between py-1.7 pl-3 pr-2.4 border-b border-light-grey">
          <h3 className="font-bold leading-[2.7rem] text-18">{title}</h3>
          <button onClick={onClose}>
            <CloseIcon className="w-1.6 h-1.6" />
          </button>
        </section>
        <section className="px-3 pt-2.1" key={`${isShowing}`}>
          {children}
        </section>
        {footer}
      </div>
    </Transition>
  );
};

export default Drawer;
