import React, { FC, useState } from 'react';
import { Popover } from '@headlessui/react';
import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import { APPOINTMENT_STATUS, ERROR_REASON } from 'utils/constants';
import DuplicateAppointment from 'components/DuplicateAppointment/DupplicateAppointment';
import PartialPatient from 'components/PartialPatient/PartialPatient';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { renderToast } from 'components/Toast/Toast';
import moment from 'moment';
import { getErrorReason } from 'utils/common';
import axiosInstance from 'apis/axiosInstance';

interface ThreeDotsOptionProps {
  appointment: any;
  options: string[];
  onClickAccept: () => void;
  onClickReject: () => void;
  updateDashboardUI: () => Promise<void>;
}

const {
  DUPLICATE_BOOKING,
  OUT_OF_OFFICE_HOURS,
  OUT_OF_PRACTITIONER_HOURS,
  PARTIAL_PATIENT_MATCH,
  GENERAL_ERROR,
  PATIENT_NEED_ADD_TO_FAMILY,
  WRITE_FAIL,
  UNFOUNDED_AVAILABLE_PRACTITIONER,
} = ERROR_REASON;

const ThreeDotsOption: FC<ThreeDotsOptionProps> = ({
  appointment,
  options,
  onClickAccept,
  onClickReject,
  updateDashboardUI,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { id, appointmentDate, patientName = '', timezone } = appointment;
  const errorReason = getErrorReason(appointment);

  const [isDuplicatedApptModalOpen, setIsDuplicatedApptModalOpen] =
    useState(false);

  // Service is updated after resolving partial patient match
  const [isChecking, setIsChecking] = useState(false);

  const checkedIfErrorIsTheSame = async () => {
    setIsChecking(true);
    let isErrorTheSame = true;
    try {
      const response = await axiosInstance.get(
        `/appointments/${appointment.id}`
      );
      const errorReason = getErrorReason(response.data);
      if (!errorReason) {
        isErrorTheSame = false;
        updateDashboardUI();
        handleAppointmentAlreadyResolved(false);
      }
    } catch (error) {
      renderToast({
        type: 'error',
        message: 'Something went wrong. Please try again later',
      });
    }
    setIsChecking(false);
    return isErrorTheSame;
  };

  const handleClickOpen = async () => {
    const isNormalApptReschedule =
      !appointment.errorReason &&
      appointment.status === APPOINTMENT_STATUS.NEW.KEY;

    if (!isNormalApptReschedule) {
      const isErrorTheSame = await checkedIfErrorIsTheSame();
      if (!isErrorTheSame) return;
    }

    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);

    if (isDuplicatedApptModalOpen) {
      setIsDuplicatedApptModalOpen(false);
    }
  };

  const handleAppointmentAlreadyResolved = (isDuplicateResolved: boolean) => {
    const message = (
      <>
        This{' '}
        {isDuplicateResolved
          ? 'duplicate appointment'
          : 'partial patient match'}{' '}
        error for <strong>{patientName}</strong> has been resolved. Please
        accept or reject the appointment below.
      </>
    );

    renderToast({ message, type: 'success' });
  };

  const getErrorDropdownLabel = () => {
    if (errorReason === DUPLICATE_BOOKING) {
      return 'Modify Appointment';
    }
    if (errorReason === PARTIAL_PATIENT_MATCH) {
      return 'Select matching patient';
    }
  };

  const isErrorSelectAnotherTimeSlot = [
    DUPLICATE_BOOKING,
    OUT_OF_OFFICE_HOURS,
    OUT_OF_PRACTITIONER_HOURS,
    UNFOUNDED_AVAILABLE_PRACTITIONER,
  ].includes(errorReason);

  const isInTodayTab = options.length === 1;

  const isDropdownDisabled = isInTodayTab && !errorReason;

  const isAppointmentInThePast = moment(appointmentDate, 'YYYY-MM-DD').isBefore(
    moment.tz(timezone).format('YYYY-MM-DD'),
    'day'
  );

  const renderActionOptions = () => {
    if (errorReason === GENERAL_ERROR || errorReason === WRITE_FAIL) {
      return (
        <>
          {!appointment.isAccepted && (
            <Popover.Button
              className="w-full px-2.5 hover:bg-opacity-10 hover:text-magenta text-left"
              onClick={onClickAccept}
            >
              Accept
            </Popover.Button>
          )}

          <Popover.Button
            className="w-full px-2.5 hover:bg-opacity-10 hover:text-magenta text-left"
            onClick={onClickReject}
          >
            Reject
          </Popover.Button>
        </>
      );
    }

    if (errorReason === PATIENT_NEED_ADD_TO_FAMILY) {
      return (
        <>
          {!appointment.isAccepted && (
            <Popover.Button
              className="w-full px-2.5 hover:bg-opacity-10 hover:text-magenta text-left"
              onClick={onClickAccept}
            >
              Accept
            </Popover.Button>
          )}

          <Popover.Button
            className="w-full px-2.5 text-left disabled:cursor-not-allowed disabled:opacity-30"
            disabled={true}
          >
            Modify Appointment
          </Popover.Button>

          <Popover.Button
            className="w-full px-2.5 hover:bg-opacity-10 hover:text-magenta text-left"
            onClick={onClickReject}
          >
            Reject
          </Popover.Button>
        </>
      );
    }

    if (
      errorReason === PARTIAL_PATIENT_MATCH ||
      errorReason === DUPLICATE_BOOKING
    ) {
      return (
        <>
          <Popover.Button
            className="w-full px-2.5 hover:bg-opacity-10 hover:text-magenta text-left"
            onClick={handleClickOpen}
          >
            {getErrorDropdownLabel()}
          </Popover.Button>
          <Popover.Button
            className="w-full px-2.5 hover:bg-opacity-10 hover:text-magenta text-left"
            onClick={onClickReject}
          >
            Reject
          </Popover.Button>
        </>
      );
    }

    return (
      <>
        {!appointment.isAccepted && (
          <Popover.Button
            className="w-full px-2.5 hover:bg-opacity-10 hover:text-magenta text-left"
            onClick={onClickAccept}
          >
            Accept
          </Popover.Button>
        )}

        <Popover.Button
          className="w-full px-2.5 hover:bg-opacity-10 hover:text-magenta text-left"
          onClick={handleClickOpen}
        >
          Modify Appointment
        </Popover.Button>

        <Popover.Button
          className="w-full px-2.5 hover:bg-opacity-10 hover:text-magenta text-left"
          onClick={onClickReject}
        >
          Reject
        </Popover.Button>
      </>
    );
  };

  const isNormalApptEnableSelectAnotherTimeSlot =
    isModalOpen &&
    !errorReason &&
    appointment.status === APPOINTMENT_STATUS.NEW.KEY;

  const isSelectAnotherTimeSlotEnable =
    (isModalOpen && isErrorSelectAnotherTimeSlot) ||
    isNormalApptEnableSelectAnotherTimeSlot ||
    isDuplicatedApptModalOpen;

  return (
    <>
      {isChecking && <LoadingScreen />}
      {isSelectAnotherTimeSlotEnable && (
        <DuplicateAppointment
          id={id}
          mode={errorReason}
          onClose={handleCloseModal}
          onAppointmentAlreadyResolved={() =>
            handleAppointmentAlreadyResolved(true)
          }
        />
      )}
      {isModalOpen && [PARTIAL_PATIENT_MATCH].includes(errorReason) && (
        <PartialPatient
          id={id}
          onClose={handleCloseModal}
          onAppointmentAlreadyResolved={() =>
            handleAppointmentAlreadyResolved(false)
          }
          onClickAccept={() => {
            onClickAccept();
            handleCloseModal();
          }}
        />
      )}
      <Popover className="relative hidden group-hover:block">
        <Popover.Button
          className="flex items-center disabled:cursor-not-allowed disabled:opacity-30"
          disabled={isDropdownDisabled}
        >
          <MoreIcon className="h-2.2" />
        </Popover.Button>
        <Popover.Panel
          className={`flex flex-col gap-y-1.6 absolute py-2 right-0 z-10 bg-white shadow-primary rounded-[0.8rem] mt-0.6 ${
            [GENERAL_ERROR, WRITE_FAIL].includes(errorReason) ||
            isAppointmentInThePast
              ? 'w-16.5'
              : 'w-21'
          }`}
        >
          {renderActionOptions()}
        </Popover.Panel>
      </Popover>
    </>
  );
};

export default ThreeDotsOption;
