import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import DuplicateAppointment from 'components/DuplicateAppointment/DupplicateAppointment';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import PartialPatient from 'components/PartialPatient/PartialPatient';
import useAppointmentDetail from 'hooks/useAppointmentDetail';

import { APPOINTMENT_STATUS, ERROR_REASON } from 'utils/constants';
import { ROUTES } from 'utils/constants/routes';
import { renderToast } from 'components/Toast/Toast';
import { clearHistoryLocation } from 'utils/storage';
import AppointmentDetailModal from 'components/AppointmentDetailModal/AppointmentDetailModal';
import { getErrorReason } from 'utils/common';
import useClinic from 'hooks/useClinic';

interface ErrorNavigationProps {
  appointmentId: string;
}

const ErrorNavigation: FC<ErrorNavigationProps> = ({ appointmentId }) => {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data, isLoading } = useAppointmentDetail(appointmentId);

  const { data: clinic } = useClinic();

  const isClickOnSumbit = useRef(false);

  const errorReason = data && getErrorReason(data);

  const handleCloseModal = () => {
    clearHistoryLocation();
    if (!isClickOnSumbit.current) {
      return navigate(ROUTES.APPOINTMENT, { replace: true });
    }
    setIsModalOpen(false);
  };

  const handleAppointmentAlreadyResolved = (isDuplicateResolved: boolean) => {
    const message = (
      <>
        This{' '}
        {isDuplicateResolved
          ? 'duplicate appointment'
          : 'partial patient match'}{' '}
        error for <strong>{data?.patient.name}</strong> has been resolved.
        Please accept or reject the appointment below.
      </>
    );

    renderToast({ message, type: 'success' });
  };

  useEffect(() => {
    if (!data || !clinic) return;

    const isAppointmentInThePast = moment(
      data.appointmentDate,
      'YYYY-MM-DD'
    ).isBefore(moment.tz(clinic?.timezone).format('YYYY-MM-DD'), 'day');

    let isApptErrorResolvedShown = false;

    if (data.isAccepted || isAppointmentInThePast) {
      isApptErrorResolvedShown = isAppointmentInThePast;
    } else {
      isApptErrorResolvedShown = data.status !== APPOINTMENT_STATUS.NEW.KEY;
    }

    if (!errorReason || isApptErrorResolvedShown) {
      renderToast({
        message:
          'The appointment error was either already resolved or does not exist',
        type: 'error',
      });
      navigate(ROUTES.APPOINTMENT, { replace: true });
      return;
    }

    setIsModalOpen(true);
  }, [data, clinic, errorReason, navigate]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  const isShownAppointmentDetailModal =
    errorReason === ERROR_REASON.WRITE_FAIL ||
    ([
      ERROR_REASON.DUPLICATE_BOOKING,
      ERROR_REASON.PARTIAL_PATIENT_MATCH,
    ].includes(errorReason) &&
      (data.isCancelled || data.status === APPOINTMENT_STATUS.CANCELLED.KEY));

  const isShownDuplicatedAppointmentModal =
    errorReason === ERROR_REASON.DUPLICATE_BOOKING &&
    !data.isCancelled &&
    data.status !== APPOINTMENT_STATUS.CANCELLED.KEY;

  const isShownPartialPatientMatchModal =
    errorReason === ERROR_REASON.PARTIAL_PATIENT_MATCH &&
    !data.isCancelled &&
    data.status !== APPOINTMENT_STATUS.CANCELLED.KEY;

  return (
    <>
      {isModalOpen && isShownAppointmentDetailModal && (
        <AppointmentDetailModal
          appointmentId={appointmentId!}
          onClose={handleCloseModal}
          isHiddenAcceptRejectOptions={[
            APPOINTMENT_STATUS.ACCEPTED.KEY,
            APPOINTMENT_STATUS.CANCELLED.KEY,
            APPOINTMENT_STATUS.COMPLETED.KEY,
            APPOINTMENT_STATUS.MISSED.KEY,
          ].includes(data.status)} // For accepted, cancelled, completed, missed table, hide all options. This only happens to failed_to_write
        />
      )}

      {isModalOpen && isShownDuplicatedAppointmentModal && (
        <DuplicateAppointment
          id={appointmentId!}
          mode={ERROR_REASON.DUPLICATE_BOOKING}
          onClose={handleCloseModal}
          onAppointmentAlreadyResolved={() =>
            handleAppointmentAlreadyResolved(true)
          }
        />
      )}

      {isModalOpen && isShownPartialPatientMatchModal && (
        <PartialPatient
          id={appointmentId!}
          onClose={handleCloseModal}
          onAppointmentAlreadyResolved={() =>
            handleAppointmentAlreadyResolved(false)
          }
        />
      )}
    </>
  );
};

export default ErrorNavigation;
