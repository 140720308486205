import Drawer from 'components/Drawer/Drawer';
import ScriptSnippet from 'components/ScriptSnippet';
import useWidgetGenerate from 'hooks/useWidgetGenerate';
import { FC } from 'react';
import CustomizeScheduleButton from '../WidgetSection/CustomizeScheduleButton/CustomizeScheduleButton';

interface ScriptDrawerProps {
  isShowing: boolean;
  setIsShowing: React.Dispatch<React.SetStateAction<boolean>>;
}

const ScriptDrawer: FC<ScriptDrawerProps> = ({ isShowing, setIsShowing }) => {
  const { data, isLoading } = useWidgetGenerate();

  return (
    <Drawer title="Scripts" isShowing={isShowing} setIsShowing={setIsShowing}>
      <div className="-mx-0.6 -my-0.5 flex flex-col gap-y-3.2">
        <div className="flex flex-col gap-y-1">
          <p className="font-bold">Scheduling button script</p>
          <div className="w-15">
            <ScriptSnippet
              isLoading={isLoading}
              url={`${process.env.REACT_APP_FIRSTIN_APP_URL}/static/js/widget.js?key=${data?.widgetKey}`}
              dataApp="first-in"
            />
          </div>
        </div>

        <div className="flex flex-col gap-y-1">
          <p className="font-bold">CTA button script</p>
          <div className="w-15">
            <ScriptSnippet
              isLoading={isLoading}
              url={`${process.env.REACT_APP_FIRSTIN_APP_URL}/static/js/customWidgetModal.js?key=${data?.widgetKey}`}
              dataApp="custom-script-first-in"
            />
          </div>
        </div>

        <div className="flex flex-col gap-y-1">
          <p className="font-bold">Scheduling button customization</p>
          <div className="w-15">
            <CustomizeScheduleButton />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default ScriptDrawer;
