import { FC, useState } from 'react';
import axiosInstance from 'apis/axiosInstance';
import { Popover } from '@headlessui/react';
import DuplicateAppointment from 'components/DuplicateAppointment/DupplicateAppointment';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import PartialPatient from 'components/PartialPatient/PartialPatient';
import { renderToast } from 'components/Toast/Toast';
import useMatchMutate from 'hooks/useMatchMutate';
import {
  getErrorReason,
  getOptionsForAcceptedStatus,
  getOptionsForNewStatus,
} from 'utils/common';
import { APPOINTMENT_STATUS, ERROR_REASON } from 'utils/constants';
import { IAppointmentDetail } from 'interfaces/appointments';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import AcceptAppointmentModal from 'components/AcceptAppointmentModal/AcceptAppointmentModal';

interface PopoverOptionProps {
  appointment: IAppointmentDetail;
  isAppointmentInThePast?: boolean;
  isIconOptionDisabled?: boolean;
}

const { GENERAL_ERROR, PATIENT_NEED_ADD_TO_FAMILY, WRITE_FAIL } = ERROR_REASON;

const PopoverOption: FC<PopoverOptionProps> = ({
  appointment,
  isAppointmentInThePast,
  isIconOptionDisabled,
}) => {
  const errorReason = appointment && getErrorReason(appointment);

  const matchMutate = useMatchMutate();

  const [errorModal, setErrorModal] = useState('');
  const [confirmModalMode, setConfirmModalMode] = useState('');
  const [isChecking, setIsChecking] = useState(false);

  const getOptions = () => {
    if (appointment.isAccepted) {
      return getOptionsForAcceptedStatus(errorReason);
    }

    return getOptionsForNewStatus({
      isAppointmentInThePast: !!isAppointmentInThePast,
      currentStatus: appointment.status,
      errorReason,
    });
  };

  const checkedIfErrorIsTheSame = async () => {
    setIsChecking(true);
    let isErrorTheSame = true;
    try {
      const response = await axiosInstance.get(
        `/appointments/${appointment.id}`
      );

      const errorReason = getErrorReason(response.data);

      if (!errorReason) {
        isErrorTheSame = false;
        await matchMutate(/\/appointments\?[\s\S]+/);
        handleAppointmentAlreadyResolved(false);
      }
    } catch (error) {
      renderToast({
        type: 'error',
        message: 'Something went wrong. Please try again later',
      });
    }
    setIsChecking(false);
    return isErrorTheSame;
  };

  const handleClickOption = async (option: string) => {
    if (option === 'Accept' || option === 'Reject') {
      return setConfirmModalMode(option);
    }

    const isErrorTheSame = await checkedIfErrorIsTheSame();
    if (!isErrorTheSame) return;

    if (option === 'Modify Appointment') {
      return setErrorModal('timeslot');
    }
    if (option === 'Select matching patient') {
      return setErrorModal('patient');
    }
  };

  const handleSubmitConfirmation = async () => {
    try {
      const updatedStatus =
        confirmModalMode === 'Accept' ? 'accepted' : 'cancelled';

      const toastMessage =
        confirmModalMode === 'Accept' ? 'accepted' : 'rejected';

      await axiosInstance.patch('/appointments', [
        {
          appointmentId: appointment.id,
          status: updatedStatus,
        },
      ]);
      await matchMutate(/\/appointments\?[\s\S]+/);
      renderToast({
        type: 'success',
        message: `Successfully ${toastMessage} appointment`,
      });
      setConfirmModalMode('');
    } catch (error) {
      renderToast({
        type: 'error',
        message: 'Something went wrong. Please try again later',
      });
    }
  };

  const handleAppointmentAlreadyResolved = (isDuplicateResolved: boolean) => {
    const message = (
      <>
        This{' '}
        {isDuplicateResolved
          ? 'duplicate appointment'
          : 'partial patient match'}{' '}
        error for <strong>{appointment.patient.name}</strong> has been resolved.
        Please accept or reject the appointment below.
      </>
    );

    renderToast({ message, type: 'success' });
  };

  const allowedStatus = isIconOptionDisabled
    ? [APPOINTMENT_STATUS.NEW.KEY, APPOINTMENT_STATUS.ERROR.KEY]
    : [
        APPOINTMENT_STATUS.NEW.KEY,
        APPOINTMENT_STATUS.ERROR.KEY,
        APPOINTMENT_STATUS.ACCEPTED.KEY,
      ];

  if (!allowedStatus.includes(appointment.status)) {
    return null;
  }

  const isOptionDisabled = (option: string) =>
    errorReason === PATIENT_NEED_ADD_TO_FAMILY &&
    option === 'Modify Appointment';

  return (
    <>
      {isChecking && <LoadingScreen />}
      {errorModal === 'timeslot' && (
        <DuplicateAppointment
          id={appointment.id}
          mode={errorReason}
          onClose={() => setErrorModal('')}
          onAppointmentAlreadyResolved={() =>
            handleAppointmentAlreadyResolved(true)
          }
        />
      )}
      {confirmModalMode ? (
        confirmModalMode === 'Accept' ? (
          <AcceptAppointmentModal
            appointmentId={appointment.id}
            onClose={() => setConfirmModalMode('')}
          />
        ) : (
          <ConfirmationModal
            title={`Reject appointment`}
            description={`Are you sure you want to reject this appointment?`}
            onClose={() => setConfirmModalMode('')}
            onSubmit={handleSubmitConfirmation}
            submitBtnTitle={`Yes, reject`}
          />
        )
      ) : null}
      {errorModal === 'patient' && (
        <PartialPatient
          id={appointment.id}
          onClose={() => setErrorModal('')}
          onAppointmentAlreadyResolved={() =>
            handleAppointmentAlreadyResolved(false)
          }
        />
      )}
      <Popover.Panel
        className={`flex flex-col gap-y-1.6 absolute py-2 -right-10 z-10 bg-white shadow-primary rounded-[0.8rem] mt-0.6 ${
          errorReason &&
          ![GENERAL_ERROR, WRITE_FAIL].includes(errorReason) &&
          !isAppointmentInThePast
            ? 'w-21'
            : 'w-16.5'
        }`}
      >
        {getOptions().map((option) => (
          <Popover.Button
            key={option}
            className="w-full px-2 hover:bg-opacity-10 hover:text-magenta text-left 
             disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:text-darkest-grey"
            onClick={() => handleClickOption(option)}
            disabled={isOptionDisabled(option)}
          >
            {option}
          </Popover.Button>
        ))}
      </Popover.Panel>
    </>
  );
};

export default PopoverOption;
