import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { Switch } from '@headlessui/react';
import getWebsiteName from 'utils/getWebsiteName';

interface ToggleDisplayOnSectionProps {
  control: any;
  data: any;
}

const ToggleDisplayOnSection: FC<ToggleDisplayOnSectionProps> = ({
  control,
  data,
}) => {
  return (
    <>
      <p className="font-bold min-w-[19rem]">
        Display on {data.website ? getWebsiteName(data.website) : data.name}
      </p>
      <div className="flex gap-x-1.6 items-center">
        <Controller
          name={'isWidgetVisible'}
          control={control}
          render={({ field: { value, onChange } }) => (
            <>
              <Switch
                onChange={onChange}
                checked={value}
                className={`${
                  value ? 'bg-magenta' : 'bg-[#78788029]'
                } relative inline-flex h-3.1 w-5.1 items-center rounded-full`}
              >
                <span
                  className={`${
                    value ? 'translate-x-[2.3rem]' : 'translate-x-[2px]'
                  } inline-block h-2.5 w-2.5 transform rounded-full transition bg-white`}
                />
              </Switch>
              <span className="uppercase">{value ? 'On' : 'Off'}</span>
            </>
          )}
        />
      </div>
    </>
  );
};

export default ToggleDisplayOnSection;
