import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Listbox } from '@headlessui/react';
import DefaultModal from 'components/Modals/DefaultModal';
import TextField from 'components/TextField/TextField';
import { ReactComponent as DropdownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as HelpOutlineIcon } from 'assets/icons/help_outline.svg';
import CommonButton from 'components/CommonButton/CommonButton';
import {
  DURATION_OPTIONS,
  MAX_HYGIENIST_DURATION,
  MAX_OTHER_SERVICES_DURATION,
} from 'utils/constants';
import ReactTooltip from 'react-tooltip';
import { useLocation } from 'react-router-dom';
import AdvancedSection from './AdvancedSection/AdvancedSection';
import { ROUTES } from 'utils/constants/routes';
import { convertDurationForFE } from 'utils/convertDuration';
import { range } from 'lodash';
import useSplitSchedulingService from 'hooks/useSplitSchedulingService';
import useModal from 'hooks/useModal';

const getDefaultOptions = ({
  isExamCleaningService,
  isGlobalAdmin,
  timeInterval,
}: {
  isGlobalAdmin?: boolean;
  timeInterval?: number;
  isExamCleaningService?: boolean;
}) => {
  if (isGlobalAdmin || !timeInterval) {
    return DURATION_OPTIONS;
  }

  const start = timeInterval;
  const stopAt = isExamCleaningService
    ? MAX_HYGIENIST_DURATION
    : MAX_OTHER_SERVICES_DURATION;
  // RANGE DOES NOT INLCUDE END VALUE SO ADDING 1 LAST ELEMENT
  const end = stopAt + timeInterval;
  const step = timeInterval;

  return range(start, end, step).map((duration) => duration);
};

interface CreateOrEditServiceProps {
  isCreateMode?: boolean;
  defaultValues?: {
    name: string;
    duration: number;
    status: string;
    hygienistDuration?: number;
    doctorDuration?: number;
    isSplitScheduling?: boolean;
    examPlacement?: string | null;
  };
  onClose: () => void;
  onSubmit: (data: any) => Promise<void>;
  isNameDisabled?: boolean;
  isGlobalAdmin?: boolean;
  isExamCleaningService?: boolean;
  timeInterval?: number;
  serviceId?: string;
}

export interface CreateOrEditServiceSubmitData {
  name: string;
  duration: number | null;
  status: string;
  isPracticeActive?: boolean;
  hygienistDuration?: number | null;
  doctorDuration?: number | null;
  isSplitScheduling?: boolean;
  examPlacement?: string | null;
  timeInterval?: number;
  doctorIds?: string[];
}

const CreateOrEditService: FC<CreateOrEditServiceProps> = ({
  isCreateMode,
  onClose,
  onSubmit,
  defaultValues,
  isNameDisabled,
  isGlobalAdmin,
  isExamCleaningService,
  timeInterval,
  serviceId,
}) => {
  const location = useLocation();

  const { setIsAllowed } = useModal({ onClose });

  const isAdvancedSectionDisplayed =
    location.pathname === ROUTES.SERVICE &&
    !isCreateMode &&
    isExamCleaningService;

  const { data } = useSplitSchedulingService({
    serviceId: serviceId!,
    shouldRun: isAdvancedSectionDisplayed,
  });

  const { control, formState, register, handleSubmit, watch, setValue } =
    useForm<CreateOrEditServiceSubmitData>({
      mode: 'all',
      defaultValues: {
        ...defaultValues,
        ...(isCreateMode && { name: '', duration: null, status: 'active' }),
        ...(isAdvancedSectionDisplayed &&
          defaultValues?.isSplitScheduling && {
            isSplitScheduling: defaultValues.isSplitScheduling,
            hygienistDuration: defaultValues?.hygienistDuration
              ? convertDurationForFE(defaultValues.hygienistDuration)
              : null,
            doctorDuration:
              defaultValues?.doctorDuration !== null
                ? convertDurationForFE(defaultValues!.doctorDuration!)
                : null,
            examPlacement: defaultValues?.examPlacement,
          }),
        doctorIds: [],
      },
    });

  useEffect(() => {
    if (!data) return;
    const isSplitScheduling = watch('isSplitScheduling');
    let doctorIds = data.doctors.map((doctor: any) => doctor.id);

    if (isSplitScheduling) {
      doctorIds = data.selectedDoctors.map((doctor: any) => doctor.id);
    }

    setValue('doctorIds', doctorIds);
  }, [data, setValue, watch]);

  const isSplitScheduling = watch('isSplitScheduling');

  const onStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('status', event.target.value);
    if (!isGlobalAdmin) return;

    setValue('isPracticeActive', event.target.value === 'active');
  };

  const options = getDefaultOptions({
    isExamCleaningService,
    isGlobalAdmin,
    timeInterval,
  });

  const getSplitSchedulingDuration = () => {
    let duration = 0;
    const hygienistDuration = watch('hygienistDuration');
    const doctorDuration = watch('doctorDuration');

    return (
      duration +
      (hygienistDuration ? hygienistDuration : 0) +
      (doctorDuration ? doctorDuration : 0)
    );
  };

  const onSubmitData = async (data: any) => {
    setIsAllowed(false);
    await onSubmit(data);
    setIsAllowed(true);
  };

  return (
    <DefaultModal>
      <div className="w-50 min-h-[36.2rem] text-darkest-grey">
        <h3 className="text-20 leaing-[3rem] pt-1.6 px-3 font-bold pb-2.2 border-b border-light-grey">
          {isCreateMode ? 'Create New Service' : 'Edit Service'}
        </h3>
        <div className="px-3 pt-2.4 pb-3.2 flex flex-col gap-y-2.4 text-14 leading-[2.1rem]">
          <TextField
            id={'name'}
            control={control}
            label="Service Name"
            placeholder="Input Service Name"
            rules={{ required: 'This field is required' }}
            disabled={isNameDisabled}
          />
          <div className="flex flex-col gap-y-0.8">
            <h3 className="text-darkest-grey text-16 leading-[2.4rem] font-bold">
              Duration
            </h3>
            <Controller
              name={'duration'}
              control={control}
              rules={{ required: 'This field is required' }}
              render={({ field }) => (
                <Listbox {...field} disabled={!!isSplitScheduling}>
                  {({ open }) => {
                    return (
                      <div className="relative">
                        <Listbox.Button
                          className={`relative w-full h-[4.8rem] pl-1.6 cursor-pointer rounded-[1rem] bg-white border-[0.5px] border-light-grey text-left shadow-primary hover:border-magenta hover:shadow-input-active outline-none ${
                            open ? 'border-magenta shadow-input-active' : ''
                          }`}
                        >
                          <span
                            className={`block pr-3 ${
                              field.value && !isSplitScheduling
                                ? 'text-darkest-grey'
                                : 'text-mid-grey'
                            } `}
                          >
                            {field.value
                              ? options.includes(field.value)
                                ? `${
                                    isSplitScheduling
                                      ? getSplitSchedulingDuration()
                                      : field.value
                                  } minutes`
                                : ''
                              : 'Select Duration'}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <DropdownIcon className="w-1.4 h-1.4" />
                          </span>
                        </Listbox.Button>
                        <Listbox.Options className="absolute mt-0.5 max-h-16 scrollbar w-full overflow-auto rounded-[0.8rem] bg-white py-1.5 text-base shadow-primary flex flex-col gap-y-1.5 z-10">
                          {options.map((value) => (
                            <Listbox.Option
                              key={value}
                              className="relative cursor-default select-none pl-1.6"
                              value={value}
                            >
                              {({ selected }) => (
                                <span
                                  className={`block truncate cursor-pointer hover:text-magenta ${
                                    selected ? 'text-magenta' : ''
                                  }`}
                                >
                                  {value} minutes
                                </span>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </div>
                    );
                  }}
                </Listbox>
              )}
            />
          </div>
          {isAdvancedSectionDisplayed && (
            <AdvancedSection
              control={control}
              isSplitScheduling={isSplitScheduling}
              hygienistDuration={watch('hygienistDuration')}
              doctorDuration={watch('doctorDuration')}
              timeInterval={timeInterval}
              doctors={data?.doctors}
            />
          )}
          {isCreateMode && (
            <fieldset>
              <legend className="text-darkest-grey text-16 leading-[2.4rem] font-bold">
                Status
              </legend>
              <div className="mt-1.6 flex gap-x-[11.1rem]">
                <div className="flex items-center gap-x-0.9">
                  <input
                    id="statusActive"
                    type="radio"
                    className="w-2.2 h-2.2"
                    value="active"
                    {...register('status', {
                      required: true,
                      onChange: onStatusChange,
                    })}
                  />
                  <label htmlFor="statusActive">
                    {isGlobalAdmin ? 'Available' : 'Active'}
                  </label>
                </div>
                <div className="flex items-center gap-x-0.9">
                  <input
                    id="statusInactive"
                    type="radio"
                    className="w-2.2 h-2.2"
                    value="inactive"
                    {...register('status', {
                      required: true,
                      onChange: onStatusChange,
                    })}
                  />
                  <label htmlFor="statusInactive">
                    {isGlobalAdmin ? 'Unavailable' : 'Inactive'}
                  </label>
                </div>
              </div>
            </fieldset>
          )}
          {isGlobalAdmin && isCreateMode && (
            <div className="flex gap-x-0.9 items-center">
              <input
                id={`isPracticeActive`}
                type="checkbox"
                className="cursor-pointer accent-magenta max-w-[2.2rem] h-2.2 flex flex-1 disabled:cursor-not-allowed disabled:bg-white disabled:opacity-60"
                defaultChecked={true}
                disabled={watch('status') === 'inactive'}
                {...register('isPracticeActive')}
              />
              <label htmlFor="isPracticeActive">Active by default</label>
              <button data-for="help-info-tooltip" data-tip="">
                <HelpOutlineIcon className="w-2 h-2 all-child:all-child:fill-mid-grey" />
              </button>
              <ReactTooltip
                id="help-info-tooltip"
                place="bottom"
                effect="solid"
                className="!bg-white !border-light-grey !p-0 !rounded-[0.8rem] !w-[22.9rem] !h-auto !opacity-100 z-[9999] !flex justify-center items-center !min-w-[14.1rem] shadow-elevation-09 !min-h-[2.6rem] before:hidden after:hidden"
              >
                <span className="text-11 leading-[1.8rem] text-darkest-grey flex items-center font-normal mb-0.2 px-1 py-0.2">
                  By checking this box, this service will be active for all
                  practices by default.
                </span>
              </ReactTooltip>
            </div>
          )}
          <div className="flex justify-end gap-x-1.6">
            <CommonButton
              variant="secondary"
              onClick={onClose}
              disabled={formState.isSubmitting}
            >
              Cancel
            </CommonButton>
            <CommonButton
              disabled={!formState.isValid}
              isLoading={formState.isSubmitting}
              onClick={handleSubmit(onSubmitData)}
            >
              {isCreateMode ? 'Create' : 'Update'}
            </CommonButton>
          </div>
        </div>
      </div>
    </DefaultModal>
  );
};

export default CreateOrEditService;
