import { useNavigate } from 'react-router-dom';

import AppointmentDetailModal from 'components/AppointmentDetailModal/AppointmentDetailModal';
import { renderToast } from 'components/Toast/Toast';
import useAppointmentDetail from 'hooks/useAppointmentDetail';
import { APPOINTMENT_STATUS } from 'utils/constants';
import { ROUTES } from 'utils/constants/routes';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { useEffect } from 'react';
import { clearHistoryLocation } from 'utils/storage';
import useAppointmentSearchParam from 'hooks/useAppointmentSearchParam';
import AcceptAppointmentModal from 'components/AcceptAppointmentModal/AcceptAppointmentModal';

const {
  NEW: { KEY: newStatus },
  ACCEPTED: { KEY: acceptedStatus },
  CANCELLED: { KEY: cancelledStatus },
} = APPOINTMENT_STATUS;

const ActionNavigation = () => {
  const {
    isActionParamCombinationValid,
    params: { action, appointmentId },
  } = useAppointmentSearchParam();

  const navigate = useNavigate();

  const { data, isLoading } = useAppointmentDetail(
    appointmentId ? appointmentId : '',
    Boolean(isActionParamCombinationValid)
  );

  useEffect(() => {
    if (isLoading || data?.status === newStatus || action === 'review') {
      return;
    }

    let message = '';

    if (data?.status === acceptedStatus) {
      message = 'Appointment has already been accepted';
    }

    if (data?.status === cancelledStatus) {
      message = 'Appointment has already been rejected';
    }

    if (message) {
      renderToast({
        type: 'info',
        message,
      });
    }

    navigate(ROUTES.APPOINTMENT, { replace: true });
  }, [action, data?.status, isLoading, navigate]);

  const onClose = () => {
    clearHistoryLocation();
    navigate(ROUTES.APPOINTMENT, { replace: true });
  };

  if (!isActionParamCombinationValid) {
    return null;
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (data?.status !== newStatus && action === 'accept') {
    return null;
  }

  if (action === 'accept') {
    return (
      <AcceptAppointmentModal
        appointmentId={appointmentId!}
        onClose={onClose}
      />
    );
  }

  if (action === 'review') {
    return (
      <AppointmentDetailModal
        appointmentId={appointmentId!}
        onClose={onClose}
        isHiddenAcceptRejectOptions={[
          APPOINTMENT_STATUS.ACCEPTED.KEY,
          APPOINTMENT_STATUS.CANCELLED.KEY,
          APPOINTMENT_STATUS.COMPLETED.KEY,
          APPOINTMENT_STATUS.MISSED.KEY,
        ].includes(data.status)}
      />
    );
  }

  return null;
};

export default ActionNavigation;
