import DefaultModal from 'components/Modals/DefaultModal';
import { FC, useEffect, useRef, useState } from 'react';
import PhoneField from '../PhoneField/PhoneField';
import { useForm } from 'react-hook-form';
import TextField from 'components/TextField/TextField';
import CommonButton from 'components/CommonButton/CommonButton';
import { IClinicResponse } from 'hooks/useAllClinic';
import useModal from 'hooks/useModal';
import useGoogleBookOnline from 'hooks/useGoogleBookOnline';
import axiosInstance from 'apis/axiosInstance';
import { renderToast } from 'components/Toast/Toast';

interface GoogleBookButtonModalProps {
  selectedPractice: IClinicResponse;
  onClose: () => void;
  onUpdate: () => Promise<void>;
}

const GoogleBookButtonModal: FC<GoogleBookButtonModalProps> = ({
  selectedPractice,
  onClose,
  onUpdate,
}) => {
  const { data } = useGoogleBookOnline({
    slug: selectedPractice.slug,
  });

  const { control, formState, handleSubmit, reset } = useForm({
    defaultValues: {
      latitude: selectedPractice.latitude,
      longitude: selectedPractice.longitude,
      website: selectedPractice.website,
      phoneNumber: selectedPractice.phoneNumber,
      country: selectedPractice.location.country,
      region: selectedPractice.location.state,
      locality: selectedPractice.location.city,
      streetAddress: selectedPractice.address,
      postalCode: selectedPractice.zip,
    },
    mode: 'all',
  });
  const [isLastElementVisible, setIsLastElementVisible] = useState(false);

  const { setIsAllowed } = useModal({ onClose });

  const streetAddressDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const streetAddressDiv = streetAddressDivRef.current;
    if (!streetAddressDiv) return;

    const intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsLastElementVisible(true);
          return;
        }
        setIsLastElementVisible(false);
      });
    });

    intersectionObserver.observe(streetAddressDiv);

    return () => {
      intersectionObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!data) return;
    const formData = {
      latitude: data.latitude,
      longitude: data.longitude,
      website: data.website,
      phoneNumber: `${data.phoneNumber}`.replace('+', ''),
      country: data.country,
      region: data.region,
      locality: data.locality,
      streetAddress: data.streetAddress,
      postalCode: data.postalCode,
    };
    reset(formData);
  }, [data, reset]);

  const onSubmit = async (data: {
    latitude: number;
    longitude: number;
    website: string;
    phoneNumber: string;
    country: string;
    region: string;
    locality: string;
    streetAddress: string;
    postalCode: string;
  }) => {
    setIsAllowed(false);
    try {
      await axiosInstance.put(`/google-book-online/${selectedPractice.slug}`, {
        ...data,
        clinicName: selectedPractice.name,
        phoneNumber: `+${data.phoneNumber}`,
      });
      await onUpdate();
      renderToast({
        message:
          'The Google Book Online button has been successfully set up. Please allow up to 3 working days for it to go live.',
        type: 'success',
      });
      onClose();
    } catch (error) {
      renderToast({
        message: 'An error has occurred. Please try again.',
        type: 'error',
      });
    }
    setIsAllowed(true);
  };

  return (
    <DefaultModal>
      <div className="w-[50rem] h-[74.4rem] pt-3 px-3 text-darkest-grey text-14 leading-[2.1rem]">
        <h3 className="font-bold text-20 leading-[3rem]">
          Google Book Online Details
        </h3>
        <p className="mt-1.6">
          Please review the details below carefully before confirming the Google
          setup to ensure accuracy.
        </p>
        <div className="relative">
          {!isLastElementVisible && (
            <div className="h-[10rem] bg-blur-bg-bottom-2 absolute bottom-0 z-10 w-[calc(100%-1.6rem)]" />
          )}
          <div className="flex flex-col gap-y-2.4 mt-1.6 max-h-[55rem] overflow-y-auto scrollbar px-1 -mx-1 pb-4">
            <div className="flex flex-col gap-y-0.8">
              <label className="font-bold">
                Phone
                <span className="text-mid-grey">*</span>
              </label>
              <PhoneField
                control={control}
                phoneNumber={selectedPractice.phoneNumber}
              />
            </div>
            <div className="flex flex-col gap-y-0.8">
              <label className="font-bold">
                Website
                <span className="text-mid-grey">*</span>
              </label>
              <TextField
                id={'website'}
                control={control}
                rules={{ required: 'This field is required' }}
                placeholder="Input Website"
              />
            </div>
            <div className="flex flex-col gap-y-0.8">
              <label className="font-bold">
                Latitude
                <span className="text-mid-grey">*</span>
              </label>
              <TextField
                id={'latitude'}
                control={control}
                rules={{ required: 'This field is required' }}
                placeholder="Input Latitude"
              />
            </div>
            <div className="flex flex-col gap-y-0.8">
              <label className="font-bold">
                Longitude
                <span className="text-mid-grey">*</span>
              </label>
              <TextField
                id={'longitude'}
                control={control}
                rules={{ required: 'This field is required' }}
                placeholder="Input Longitude"
              />
            </div>
            <div className="flex flex-col gap-y-0.8">
              <label className="font-bold">
                Country
                <span className="text-mid-grey">*</span>
              </label>
              <TextField
                id={'country'}
                control={control}
                rules={{ required: 'This field is required' }}
                placeholder="Input Country"
              />
            </div>
            <div className="flex flex-col gap-y-0.8">
              <label className="font-bold">
                Locality
                <span className="text-mid-grey">*</span>
              </label>
              <TextField
                id={'locality'}
                control={control}
                rules={{ required: 'This field is required' }}
                placeholder="Input Locality"
              />
            </div>
            <div className="flex flex-col gap-y-0.8">
              <label className="font-bold">
                Region
                <span className="text-mid-grey">*</span>
              </label>
              <TextField
                id={'region'}
                control={control}
                rules={{ required: 'This field is required' }}
                placeholder="Input Region"
              />
            </div>
            <div className="flex flex-col gap-y-0.8">
              <label className="font-bold">
                Postal code
                <span className="text-mid-grey">*</span>
              </label>
              <TextField
                id={'postalCode'}
                control={control}
                rules={{ required: 'This field is required' }}
                placeholder="Input Postal Code"
              />
            </div>
            <div className="flex flex-col gap-y-0.8" ref={streetAddressDivRef}>
              <label className="font-bold">
                Street Address
                <span className="text-mid-grey">*</span>
              </label>
              <TextField
                id={'streetAddress'}
                control={control}
                rules={{ required: 'This field is required' }}
                placeholder="Input Street Address"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-x-1.6">
          <CommonButton variant="secondary" onClick={onClose}>
            Cancel
          </CommonButton>
          <CommonButton
            disabled={!formState.isValid}
            onClick={handleSubmit(onSubmit)}
            isLoading={formState.isSubmitting}
          >
            Set Up
          </CommonButton>
        </div>
      </div>
    </DefaultModal>
  );
};

export default GoogleBookButtonModal;
