import { FC } from 'react';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import ReactTooltip from 'react-tooltip';
import { Controller } from 'react-hook-form';
import { Switch } from '@headlessui/react';

interface AutoConfirmAppointmentSectionProps {
  control: any;
}

const AutoConfirmAppointmentSection: FC<AutoConfirmAppointmentSectionProps> = ({
  control,
}) => {
  return (
    <>
      <div className="flex justify-between items-center text-14 leading-[2.1rem]">
        <div className="flex items-center gap-x-0.8 mb-1">
          <h3 className="font-bold">Auto-Confirm Online Requests</h3>
          <button data-for="autoConfirmAppointmentInfo" data-tip="">
            <InfoIcon className="w-1.6 h-1.6 all-child:fill-mid-grey" />
          </button>
          <ReactTooltip
            id="autoConfirmAppointmentInfo"
            effect="solid"
            place="bottom"
            className="!bg-white !rounded-[0.8rem] !w-[18.5rem] !h-[auto] z-[9999] flex justify-center items-center !opacity-100 shadow-elevation-07 after:hidden before:hidden !px-[10px] !py-[4px]"
          >
            <span className="text-11 text-darkest-grey flex items-center font-normal">
              By activating this setting, you will no longer have to accept
              appointment requests, and patients will automatically receive a
              message confirming their appointment request.
            </span>
          </ReactTooltip>
        </div>
      </div>
      <Controller
        name={'isAutoConfirmAppointment'}
        control={control}
        render={({ field }) => {
          return (
            <div className="flex gap-x-1.6 items-center">
              <Switch
                onChange={field.onChange}
                checked={field.value}
                className={`${
                  field.value ? 'bg-magenta' : 'bg-[#78788029]'
                } relative inline-flex h-3.1 w-5.1 items-center rounded-full`}
              >
                <span
                  className={`${
                    field.value ? 'translate-x-[2.3rem]' : 'translate-x-[2px]'
                  } inline-block h-2.5 w-2.5 transform rounded-full transition bg-white`}
                />
              </Switch>
              <span className="uppercase">{field.value ? 'On' : 'Off'}</span>
            </div>
          );
        }}
      />
    </>
  );
};

export default AutoConfirmAppointmentSection;
