import { useState } from 'react';
import Table from './Table/Table';
import CommonButton from 'components/CommonButton/CommonButton';
import AdvancedTrackingDrawer from './AdvancedTrackingDrawer/AdvancedTrackingDrawer';
import ScriptDrawer from './ScriptDrawer/ScriptDrawer';

const OnlineSchedulerPage = () => {
  const [isAdvanedShowing, setIsAdvanedShowing] = useState(false);
  const [isScriptShowing, setScriptShowing] = useState(false);

  return (
    <div className="pl-3 pr-4 py-2.4 text-14 leading-[2.1rem]">
      <AdvancedTrackingDrawer
        isShowing={isAdvanedShowing}
        setIsShowing={setIsAdvanedShowing}
      />

      <ScriptDrawer
        isShowing={isScriptShowing}
        setIsShowing={setScriptShowing}
      />

      <section className="flex justify-between ">
        <h3 className="text-24 leading-[3.6rem] font-bold">Online Scheduler</h3>

        <div className="flex gap-x-0.8">
          <CommonButton
            variant="secondary"
            className="w-12.7"
            onClick={() => setScriptShowing(true)}
          >
            Scripts
          </CommonButton>
          <CommonButton
            variant="secondary"
            className="w-18"
            onClick={() => setIsAdvanedShowing(true)}
          >
            Advanced
          </CommonButton>
        </div>
      </section>

      <section className="py-2.4">
        <p className="font-bold">Service Links</p>
        <div className="bg-white shadow-primary rounded-[1rem] mt-1.6">
          <Table />
        </div>
      </section>
    </div>
  );
};

export default OnlineSchedulerPage;
