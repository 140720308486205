import { FC, useState } from 'react';
import useAppointmentDetail from 'hooks/useAppointmentDetail';

import DefaultModal from 'components/Modals/DefaultModal';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as MailOutlineIcon } from 'assets/icons/mail_outline.svg';
import { ReactComponent as PhoneIphoneIcon } from 'assets/icons/phone_iphone.svg';
import { ReactComponent as BirthdayIcon } from 'assets/icons/cake.svg';
import { ReactComponent as ServiceIcon } from 'assets/icons/service.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/time-line.svg';
import { ReactComponent as PractitionerIcon } from 'assets/icons/practitioners-icon.svg';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import {
  calculateAge,
  capitalizeAvatarName,
  filterOptionsByAcceptedOrCancelled,
  getErrorReason,
  getOptionsForAcceptedStatus,
  getOptionsForNewStatus,
} from 'utils/common';
import moment from 'moment';
import { formatFullPhoneNumber } from 'utils/formatPhoneNumber';
import CommonButton from 'components/CommonButton/CommonButton';
import axiosInstance from 'apis/axiosInstance';
import { renderToast } from 'components/Toast/Toast';
import useMatchMutate from 'hooks/useMatchMutate';
import { DATE_TIME_FORMAT, datetimeFormat } from 'utils/datetime';
import AppointmentDetailStatus from './AppointmentDetailStatus/AppointmentDetailStatus';
import {
  getAppointmentStartTime,
  getAppointmentEndTime,
  getHygienistWorkingTime,
  getDoctorWorkingTime,
} from 'components/TableColumn/utils/getAppointmentTime';
import { getIsDoctorStartBeforeHyginiest } from 'pages/SchedulePage/TimeGrid/CardDetail/utils/getPractitionerDetail';
import { getDentistWorkingHour } from 'utils/getDentistWorkingHour';
import getPractitionerName from 'utils/getPractitionerName';
import useClinic from 'hooks/useClinic';
import useModal from 'hooks/useModal';

interface AppointmentDetailModalProps {
  appointmentId: string;
  isHiddenAcceptRejectOptions?: boolean;
  onClose: () => void;
}

const initialModalState = { isSubmitting: false, type: '' };

const AppointmentDetailModal: FC<AppointmentDetailModalProps> = ({
  appointmentId,
  isHiddenAcceptRejectOptions,
  onClose,
}) => {
  const { data: clinic, isLoading: isClinicLoading } = useClinic();
  const { data, isLoading: isAppointmentDetailLoading } = useAppointmentDetail(
    appointmentId,
    !!clinic
  );

  const isLoading = isClinicLoading || isAppointmentDetailLoading;

  const [modalState, setModalState] = useState(initialModalState);

  const { isAllowed, setIsAllowed } = useModal({ onClose, isLoading });

  const matchMutate = useMatchMutate();

  if (isLoading) {
    return <LoadingScreen />;
  }

  const errorReason = getErrorReason(data);

  const isAppointmentInThePast = moment(
    data.appointmentDate,
    'YYYY-MM-DD'
  ).isBefore(moment.tz(clinic?.timezone).format('YYYY-MM-DD'), 'day');

  const getOptions = () => {
    let options = [];

    if (
      isHiddenAcceptRejectOptions ||
      (data.isAccepted && errorReason === null)
    ) {
      return {
        isAppointmentAcceptable: false,
        isAppointmentRejectable: false,
      };
    }

    if (data.isAccepted) {
      options = getOptionsForAcceptedStatus(errorReason);
    } else {
      options = getOptionsForNewStatus({
        currentStatus: data.status,
        errorReason,
        isAppointmentInThePast,
      });
    }

    options = filterOptionsByAcceptedOrCancelled({
      options,
      appointmentDetails: data,
    });

    const isAppointmentAcceptable = options.includes('Accept');
    const isAppointmentRejectable = options.includes('Reject');

    return {
      isAppointmentAcceptable,
      isAppointmentRejectable,
    };
  };

  const { isAppointmentAcceptable, isAppointmentRejectable } = getOptions();

  const onSubmit = async (status: 'accept' | 'reject') => {
    setIsAllowed(false);
    setModalState({ isSubmitting: true, type: status });

    const updatedStatus = status === 'accept' ? 'accepted' : 'cancelled';
    const toastMessage = status === 'accept' ? 'accepted' : 'rejected';

    const payload = [
      {
        appointmentId: data.id,
        status: updatedStatus,
      },
    ];
    try {
      await axiosInstance.patch('/appointments', payload);
      await matchMutate(/\/appointments\?[\s\S]+/);
      renderToast({
        type: 'success',
        message: `Successfully ${toastMessage} appointment`,
      });
      onClose();
    } catch (error) {
      renderToast({
        type: 'error',
        message: 'Something went wrong. Please try again later',
      });
    }
    setIsAllowed(true);
    setModalState(initialModalState);
  };

  const onCloseModal = () => {
    if (isAllowed) {
      onClose();
    }
  };

  const renderDentistHygienistInfo = () => {
    if (data.linkedAppointment) {
      return (
        <div className="flex gap-x-1">
          <PractitionerIcon className="w-2 h-2 all-child:all-child:fill-mid-grey" />
          <div
            className={`flex flex-col ${
              getIsDoctorStartBeforeHyginiest(data) ? '' : 'flex-col-reverse'
            } `}
          >
            <div className="flex gap-x-0.5">
              <span>Doctor: </span>
              <span>
                {getPractitionerName(data.linkedAppointment.doctor)} (
                {getDoctorWorkingTime(data)})
              </span>
            </div>
            <div className="flex gap-x-0.5">
              <span>Hygienist: </span>
              <span>
                {getPractitionerName(data.doctor)} (
                {getHygienistWorkingTime(data)})
              </span>
            </div>
          </div>
        </div>
      );
    }

    if (data.isSplitScheduling) {
      return (
        <div className="flex gap-x-1">
          <PractitionerIcon className="w-2 h-2 all-child:all-child:fill-mid-grey" />
          <div className="flex flex-col-reverse">
            <div className="flex gap-x-0.5">
              <span>Doctor: </span>
              <span>{getDentistWorkingHour(data)}</span>
            </div>
            <div className="flex gap-x-0.5">
              <span>Hygienist: </span>
              <span>
                {getPractitionerName(data.doctor)} (
                {getHygienistWorkingTime(data)})
              </span>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="flex gap-x-1 items-center">
        <PractitionerIcon className="w-2 h-2 all-child:all-child:fill-mid-grey" />
        <span>{getPractitionerName(data.doctor)}</span>
      </div>
    );
  };

  return (
    <DefaultModal>
      <div className="rounded-[1.6rem] shadow-elevation-07 min-w-[70rem] p-3 text-darkest-grey text-14 leading-[2.1rem]">
        <section className="flex justify-between">
          <AppointmentDetailStatus appointment={data} />
          <button onClick={onCloseModal}>
            <CloseIcon />
          </button>
        </section>

        <section className="flex mt-1.6 gap-x-2.4">
          <div className="w-6 h-6 bg-magenta rounded-full flex items-center justify-center text-white text-16 shrink-0">
            {data.patient &&
              capitalizeAvatarName(
                `${data.patient.firstName
                  .replace(/\s/g, '')
                  .trim()} ${data.patient.lastName.replace(/\s/g, '').trim()}`
              )}
          </div>
          <div className="flex flex-col pt-1.2">
            <span className="text-18 leading-[2.7rem] font-bold">
              {data.patient
                ? `${data.patient.name}${
                    data.patient.dob
                      ? `, ${calculateAge(data.patient.dob, 'MMM DD, YYYY')}`
                      : ''
                  }`
                : '-'}
            </span>
            <span className="text-12 leading-[1.8rem]">
              Requested on:{' '}
              {moment(data.createdAt).format('MMM DD, YYYY hh:mm A')}
            </span>
          </div>
        </section>

        <main className="mt-3 flex flex-col gap-y-3 pb-3">
          <section className="grid grid-cols-2 gap-x-1.6 gap-y-1">
            <h3 className="uppercase text-11 leading-[2.1rem] font-bold text-dim-grey">
              Patient Info
            </h3>
            <h3 className="uppercase text-11 leading-[2.1rem] font-bold text-dim-grey">
              Booking Details
            </h3>

            <div className="flex gap-x-1 items-center mt-0.2">
              <MailOutlineIcon className="w-2 h-2 all-child:all-child:fill-mid-grey" />
              <span>{data.patient?.email || '-'}</span>
            </div>
            <div className="flex gap-x-1 items-center mt-0.2">
              <ServiceIcon className="w-2 h-2 all-child:fill-mid-grey" />
              <span>{data.service?.name}</span>
            </div>

            <div className="flex gap-x-1 items-center">
              <PhoneIphoneIcon className="w-2 h-2 all-child:all-child:fill-mid-grey" />
              <span>
                {data.patient
                  ? formatFullPhoneNumber(`+${data.patient.phoneNumber}`)
                  : '-'}
              </span>
            </div>
            <div className="flex gap-x-1 items-center">
              <TimeIcon className="w-2 h-2 all-child:fill-mid-grey" />
              <span>
                {datetimeFormat({
                  dateString: data.appointmentDate,
                  pattern: 'MMM DD, YYYY',
                })}{' '}
                {getAppointmentStartTime(data)}
                {' - '}
                {getAppointmentEndTime(data)}
              </span>
            </div>

            <div className="flex gap-x-1">
              <BirthdayIcon className="w-2 h-2 all-child:all-child:fill-mid-grey" />
              <span>
                {data.patient
                  ? datetimeFormat({
                      dateString: data.patient.dob,
                      pattern: DATE_TIME_FORMAT.DOB,
                    })
                  : '-'}
              </span>
            </div>
            {renderDentistHygienistInfo()}
          </section>

          <section className="grid grid-cols-2 gap-x-1.6 gap-y-1.2">
            <h3 className="uppercase text-11 leading-[2.1rem] font-bold text-dim-grey">
              Last appointment
            </h3>
            <h3 className="uppercase text-11 leading-[2.1rem] font-bold text-dim-grey">
              Next appointment
            </h3>
            <span>
              {data.lastPastAppointment
                ? `${datetimeFormat({
                    dateString: data.lastPastAppointment.appointmentDate,
                    pattern: 'MMM DD, YYYY',
                  })} - ${getPractitionerName(data.lastPastAppointment.doctor)}`
                : '-'}
            </span>
            <span>
              {data.nextFutureAppointment
                ? `${datetimeFormat({
                    dateString: data.nextFutureAppointment.appointmentDate,
                    pattern: 'MMM DD, YYYY',
                  })} - ${getPractitionerName(
                    data.nextFutureAppointment.doctor
                  )}`
                : '-'}
            </span>
          </section>

          <section>
            <h3 className="uppercase text-11 leading-[2.1rem] font-bold text-dim-grey">
              Note
            </h3>
            {data.pmsNote ? <p className="mt-1.2">{data.pmsNote}</p> : '-'}
          </section>
        </main>

        <div className="flex justify-end gap-x-1.6">
          {!isAppointmentInThePast && isAppointmentRejectable && (
            <CommonButton
              variant="secondary"
              onClick={() => onSubmit('reject')}
              isLoading={
                modalState.isSubmitting && modalState.type === 'reject'
              }
              disabled={modalState.isSubmitting && modalState.type === 'accept'}
            >
              Reject
            </CommonButton>
          )}
          {isAppointmentAcceptable && (
            <CommonButton
              onClick={() => onSubmit('accept')}
              isLoading={
                modalState.isSubmitting && modalState.type === 'accept'
              }
              disabled={modalState.isSubmitting && modalState.type === 'reject'}
            >
              Accept
            </CommonButton>
          )}
        </div>
      </div>
    </DefaultModal>
  );
};

export default AppointmentDetailModal;
