import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import { Popover } from '@headlessui/react';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import { ReactComponent as MoreIcon } from 'assets/icons/more.svg';
import { ReactComponent as SortArrow } from 'assets/icons/sort_arrow.svg';
import { ReactComponent as EmptyBucket } from 'assets/icons/empty-bucket.svg';
import { ReactComponent as RemovedInfo } from 'assets/icons/removed-info.svg';

import ErrorIcon from 'components/ErrorIcon/ErrorIcon';
import { capitalizeAvatarName, getErrorReason } from 'utils/common';
import IndeterminateCheckbox from 'pages/DashboardPage/Table/IndeterminateCheckbox/IndeterminateCheckbox';
import {
  HeaderGroup,
  useFlexLayout,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import Pagination, { ITablePaginate } from 'components/Tables/Pagination';
import useAppointment, { IAppointmentStatus } from 'hooks/useAppointment';
import ThreeDotsOption from './ThreeDotsOption/ThreeDotsOption';

import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import axiosInstance from 'apis/axiosInstance';
import { renderToast } from 'components/Toast/Toast';

import styles from './Table.module.css';
import AppointmentDetailModal from 'components/AppointmentDetailModal/AppointmentDetailModal';
import PractitionerNameColumn from 'components/TableColumn/PractitionerNameColumn/PractitionerNameColumn';
import AppointmentDateTimeColumn from 'components/TableColumn/AppointmentDateTimeColumn/AppointmentDateTimeColumn';
import useClinic from 'hooks/useClinic';
import PopoverOption from 'components/PopoverOption/PopoverOption';
import { APPOINTMENT_STATUS } from 'utils/constants';

const defaultTablePaginationState = {
  currentPage: 1,
  pageSize: 10,
};

interface TableProps {
  sort: 'asc' | 'desc';
  setSort: Dispatch<SetStateAction<'asc' | 'desc'>>;
  dateRange: { fromDate: string; toDate: string };
  sortBy: string;
  setSortBy: Dispatch<SetStateAction<string>>;
  status: IAppointmentStatus;
}

const Table: FC<TableProps> = ({
  sort,
  sortBy,
  status,
  dateRange,
  setSort,
  setSortBy,
}) => {
  const [pageSize, setPageSize] = useState(
    defaultTablePaginationState.pageSize
  );
  const [currentPage, setCurrentPage] = useState(
    defaultTablePaginationState.currentPage
  );

  const [highlightedRowId, setHighlighedRowId] = useState('');

  const [confirmModalMode, setConfirmModalMode] = useState('');

  const [selectedAppointmentId, setSelectedAppointmentId] = useState('');

  const { data: clinic } = useClinic();

  const { data, isLoading, mutate } = useAppointment({
    startDate: moment(dateRange.fromDate, 'YYYY-MM-DD')
      .startOf('days')
      .utc(true)
      .format(),

    endDate: moment(dateRange.toDate, 'YYYY-MM-DD')
      .endOf('days')
      .utc(true)
      .format(),
    limit: pageSize,
    page: currentPage,
    sort,
    sortBy,
    status,
  });

  const isCheckBoxAndThreeDotsDisabled =
    status === 'completed' ||
    status === 'missed' ||
    status === 'cancelled' ||
    status === 'accepted';

  const columns = useMemo(
    () => [
      {
        Header: 'Appointment Date & Time',
        accessor: (field: any) => {
          return <AppointmentDateTimeColumn data={field} />;
        },
        id: 'appointmentDate',
      },
      {
        Header: 'Patient',
        accessor: (field: any) => {
          return (
            <div className="flex gap-x-0.8 items-center mr-[20px]">
              {field.patient ? (
                <>
                  <div className="w-2.4 h-2.4 bg-magenta rounded-full flex items-center justify-center text-white text-[1rem] shrink-0">
                    {field.patient
                      ? capitalizeAvatarName(
                          `${field.patient.firstName
                            .replace(/\s/g, '')
                            .trim()} ${field.patient.lastName
                            .replace(/\s/g, '')
                            .trim()}`
                        )
                      : {}}
                  </div>
                  <div className="words-break">{field.patient.name}</div>
                </>
              ) : (
                <div />
              )}
            </div>
          );
        },
        id: 'patientName',
      },
      {
        Header: 'Practitioner',
        accessor: (field: any) => {
          return <PractitionerNameColumn field={field} />;
        },
        id: 'doctorName',
      },
      {
        Header: 'Service',
        accessor: (field: any) => {
          const isServiceAvailable =
            !field.service.deletedAt &&
            field.service.isActive &&
            field.service.isAvailable;

          return (
            <div className="words-break pr-5">
              {field.service.name}
              {!isServiceAvailable && (
                <>
                  <RemovedInfo
                    className="inline-block ml-0.6"
                    data-for={field.service.id}
                    data-tip=""
                  />
                  <ReactTooltip
                    id={field.service.id}
                    place="bottom"
                    effect="solid"
                    className={`${styles.tooltip} shadow-tooltip`}
                  >
                    <span className="text-11 text-darkest-grey flex h-full items-center normal-case">
                      This service has been deactivated or removed
                    </span>
                  </ReactTooltip>
                </>
              )}
            </div>
          );
        },
        width: 200,
        id: 'serviceName',
      },
      {
        Header: 'Requested Time',
        accessor: (field: any) => {
          return (
            <span>
              {moment(field.createdAt).format('MMM DD, YYYY')}
              <br />
              {moment(field.createdAt).format('hh:mm A')}
            </span>
          );
        },
        id: 'createdAt',
      },
      {
        id: 'error_management',
        width: 40,
        Cell: ({ row }: any) => {
          const { id } = row.original;

          const isAppointmentInThePast = moment(
            row.original.appointmentDate,
            'YYYY-MM-DD'
          ).isBefore(moment.tz(clinic?.timezone).format('YYYY-MM-DD'), 'day');

          const errorReason = getErrorReason(row.original);

          const isAppointmentAcceptedInThePast =
            isAppointmentInThePast &&
            row.original.status === APPOINTMENT_STATUS.ACCEPTED.KEY;

          const isIconOptionDisabled =
            status === APPOINTMENT_STATUS.ACCEPTED.KEY ||
            isAppointmentAcceptedInThePast;

          // EXPLICITLY DETECT IF ERROR ICON IS CLICKABLE
          const isErrorIconClickable = [
            APPOINTMENT_STATUS.NEW.KEY,
            APPOINTMENT_STATUS.ERROR.KEY,
          ].includes(status);

          return (
            <ErrorIcon errorReason={errorReason} id={id}>
              {isErrorIconClickable && (
                <PopoverOption
                  appointment={row.original}
                  isAppointmentInThePast={isAppointmentInThePast}
                  isIconOptionDisabled={isIconOptionDisabled}
                />
              )}
            </ErrorIcon>
          );
        },
      },
      {
        id: 'action_btn',
        width: 25,
        Header: ({ selectedFlatRows }: any) => {
          const options = status === 'new' ? ['Accept', 'Reject'] : ['Reject'];
          return (
            <Popover className="relative">
              <Popover.Button
                disabled={selectedFlatRows.length === 0}
                className="flex items-center disabled:cursor-not-allowed disabled:opacity-30"
              >
                <MoreIcon className="h-2.2" />
              </Popover.Button>

              <Popover.Panel className="flex flex-col gap-y-1.6 absolute py-2 right-0 z-10 w-16.5 bg-white shadow-primary rounded-[0.8rem] mt-0.6">
                {options.map((title) => (
                  <Popover.Button
                    key={title}
                    className="w-full px-2.5 hover:bg-opacity-10 hover:text-magenta text-left text-14 leaing-[2.1rem] text-darkest-grey"
                    onClick={() => setConfirmModalMode(title)}
                  >
                    {title}
                  </Popover.Button>
                ))}
              </Popover.Panel>
            </Popover>
          );
        },
        Cell: ({ row }: any) => {
          const isAppointmentInThePast = moment(
            row.original.appointmentDate,
            'YYYY-MM-DD'
          ).isBefore(moment.tz(clinic?.timezone).format('YYYY-MM-DD'), 'day');

          if (isCheckBoxAndThreeDotsDisabled) {
            return null;
          }

          if (
            isAppointmentInThePast &&
            row.original.status === APPOINTMENT_STATUS.ACCEPTED.KEY
          ) {
            return null;
          }

          return (
            <ThreeDotsOption
              key={highlightedRowId}
              appointment={row.original}
              status={status}
              isAppointmentInThePast={isAppointmentInThePast}
            />
          );
        },
      },
    ],
    [clinic?.timezone, highlightedRowId, isCheckBoxAndThreeDotsDisabled, status]
  );

  const dataTable = useMemo(() => {
    if (isLoading) {
      return { data: [], metadata: null };
    }
    return { data: data.data, metadata: data.metadata };
  }, [data?.data, data?.metadata, isLoading]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data: dataTable.data ? dataTable.data : [],
      manualPagination: true,
      pageCount: dataTable.metadata ? data.metadata.total : 0,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    useFlexLayout,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          width: 50,
          Header: ({ rows, toggleRowSelected, selectedFlatRows }) => {
            const style = {
              cursor: 'pointer',
            };

            const numberOfNonErrorRows = rows.reduce((previous, current) => {
              const errorReason = getErrorReason(current.original as any);
              if (!errorReason) {
                return previous + 1;
              }
              return previous;
            }, 0);

            const checked: boolean =
              rows.length > 1 &&
              numberOfNonErrorRows > 0 &&
              selectedFlatRows.length === numberOfNonErrorRows;

            const indeterminate: boolean =
              !checked && selectedFlatRows.length > 0;

            const disabled: boolean = rows.length === 1;

            const overiddenOnChange = (
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              rows.forEach((row) => {
                const errorReason = getErrorReason(row.original as any);
                if (!errorReason) {
                  toggleRowSelected(row.id, event.currentTarget.checked);
                }
              });
            };

            const modifiedToggleAllRowsProps = {
              onChange: overiddenOnChange,
              style: style,
              checked: checked,
              indeterminate: indeterminate,
              disabled: disabled,
            };

            return (
              <div className="flex items-center pl-1.7">
                <IndeterminateCheckbox
                  {...modifiedToggleAllRowsProps}
                  isDisabled={
                    isCheckBoxAndThreeDotsDisabled ||
                    status === 'error' ||
                    disabled
                  }
                />
              </div>
            );
          },
          Cell: ({ row, rows }) => {
            const disabled: boolean = rows.length === 1;
            const errorReason = !!getErrorReason(row.original as any);
            const isDisabled =
              errorReason ||
              isCheckBoxAndThreeDotsDisabled ||
              status === 'error' ||
              disabled;

            return (
              <div className="flex items-center pl-1.7">
                <IndeterminateCheckbox
                  isDisabled={isDisabled}
                  {...row.getToggleRowSelectedProps()}
                />
              </div>
            );
          },
        },
        ...columns,
      ]);
    }
  );

  const isActionColumn = (columnId: string) =>
    ['selection', 'action_btn', 'error_management'].includes(columnId);

  const handlePaginating = (data: ITablePaginate) => {
    setCurrentPage(data.perPage === pageSize ? data.currPage : 1);
    setPageSize(data.perPage);
  };

  const handleSortColumn = (column: HeaderGroup<object>) => {
    if (isActionColumn(column.id)) return;
    setCurrentPage(1);
    setSortBy(column.id);
    if (column.id === sortBy) {
      return setSort((sort) => (sort === 'asc' ? 'desc' : 'asc'));
    }
    setSort('asc');
  };

  const handleOnCellClicked = async (cell: any) => {
    if (isActionColumn(cell.column.id)) return;

    setSelectedAppointmentId(cell.row.original.id);
  };

  const content = `${selectedFlatRows.length} ${
    selectedFlatRows.length > 1 ? 'appointments' : 'appointment'
  }`;

  const handleSubmitConfirmation = async () => {
    try {
      const updatedStatus =
        confirmModalMode === 'Accept' ? 'accepted' : 'cancelled';

      const toastMessage =
        confirmModalMode === 'Accept' ? 'accepted' : 'rejected';

      const body = selectedFlatRows.map((row: any) => ({
        appointmentId: row.original.id,
        status: updatedStatus,
      }));

      await axiosInstance.patch('/appointments', body);
      await mutate();
      renderToast({
        type: 'success',
        message: `Successfully ${toastMessage} ${content}`,
      });
      setConfirmModalMode('');
    } catch (error) {
      renderToast({
        type: 'error',
        message: 'Something went wrong. Please try again later',
      });
    }
  };

  const renderTableBody = () => {
    if (isLoading) {
      return (
        <tbody>
          <tr>
            <td colSpan={6}>
              <div className="w-full h-[37rem] flex justify-center items-center">
                <LoadingSpinner className="all-child:fill-magenta" />
              </div>
            </td>
          </tr>
        </tbody>
      );
    }
    if (data.metadata.total === 0) {
      return (
        <tbody>
          <tr>
            <td colSpan={6}>
              <div className="w-full h-[24rem] flex flex-col gap-y-1.6 justify-center items-center">
                <EmptyBucket />
                <span>No appointment available on this list</span>
              </div>
            </td>
          </tr>
        </tbody>
      );
    }

    return (
      <tbody {...getTableBodyProps()} className="text-darkest-grey">
        {page.map((row) => {
          prepareRow(row);
          return (
            <tr
              onMouseEnter={() => setHighlighedRowId(row.id)}
              {...row.getRowProps()}
              className="bg-white border-b border-light-grey px-2 group hover:bg-lightPink items-center cursor-pointer"
            >
              {row.cells.map((cell: any) => {
                return (
                  <td
                    onClick={() => handleOnCellClicked(cell)}
                    className={`text-left p-0 py-1.9 my-auto`}
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    );
  };

  return (
    <>
      {confirmModalMode && (
        <ConfirmationModal
          title={
            confirmModalMode === 'Accept'
              ? 'Accepting multiple appointments'
              : `Reject ${content}`
          }
          description={
            confirmModalMode === 'Accept'
              ? 'You are in the process of accepting multiple appointments. To proceed, click the "Yes, accept" button. Alternatively, you can review and take action on each appointment individually.'
              : `Are you sure you want to ${confirmModalMode.toLowerCase()} ${content}`
          }
          onClose={() => setConfirmModalMode('')}
          onSubmit={handleSubmitConfirmation}
          submitBtnTitle={`Yes, ${confirmModalMode.toLowerCase()}`}
        />
      )}
      {selectedAppointmentId && (
        <AppointmentDetailModal
          appointmentId={selectedAppointmentId}
          onClose={() => setSelectedAppointmentId('')}
          isHiddenAcceptRejectOptions={[
            APPOINTMENT_STATUS.ACCEPTED.KEY,
            APPOINTMENT_STATUS.CANCELLED.KEY,
            APPOINTMENT_STATUS.COMPLETED.KEY,
            APPOINTMENT_STATUS.MISSED.KEY,
          ].includes(status)} // For accepted, cancelled, completed, missed table, hide all options
        />
      )}
      <table {...getTableProps()} className="w-full text-14 leading-[2.1rem]">
        <thead className="font-bold">
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="px-2 items-center"
            >
              {headerGroup.headers.map((column) => {
                return (
                  <th
                    {...column.getHeaderProps()}
                    className="text-left p-0 py-1.9"
                  >
                    <div
                      className="flex items-center gap-x-0.9 cursor-pointer"
                      onClick={() => handleSortColumn(column)}
                    >
                      <span className="uppercase text-dim-grey text-11 leading-[1.8rem]">
                        {column.render('Header')}
                      </span>

                      {!isActionColumn(column.id) && (
                        <span>
                          <SortArrow
                            className={`${
                              column.id === sortBy && 'fill-magenta'
                            } ${
                              column.id === sortBy &&
                              sort === 'asc' &&
                              'rotate-180'
                            } transition-all`}
                          />
                        </span>
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        {renderTableBody()}
      </table>
      {dataTable.metadata && dataTable.metadata.total > 0 && (
        <div className="py-1.6">
          <Pagination
            isBorderNotIncluded
            handlePaginating={handlePaginating}
            data={{
              currPage: dataTable.metadata.page,
              perPage: dataTable.metadata.limit,
              total: dataTable.metadata.total,
            }}
          />
        </div>
      )}
    </>
  );
};

export default Table;
