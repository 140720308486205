import useSWR from 'swr';
import axiosInstance from 'apis/axiosInstance';
import { loadSelectedClinicId } from 'utils/storage';

interface IWidgetKeyResponse {
  clinic: {
    metadata: {
      firstColor: string;
      secondColor: string;
      textColor: string;
    };
  };
}

const useWidget = (widgetKey?: string) => {
  const id = loadSelectedClinicId();

  const { data, error, mutate } = useSWR<IWidgetKeyResponse>(
    widgetKey ? [`/widget?key=${widgetKey}`, id] : null,
    async (...resource) => {
      const [url] = resource;
      const response = await axiosInstance.get(url);

      return response.data;
    }
  );

  return {
    data,
    isLoading: !error && !data,
    error,
    mutate,
  };
};

export default useWidget;
