import useSWR from 'swr';
import axiosInstance from 'apis/axiosInstance';

interface IUseGoogleBookOnlineParams {
  slug: string;
}

const useGoogleBookOnline = (params: IUseGoogleBookOnlineParams) => {
  const url = `/google-book-online/${params.slug}`;

  const { data, error, mutate } = useSWR(url, async (url) => {
    const response = await axiosInstance.get(url);
    return response.data;
  });

  return {
    data,
    error,
    isLoading: !data && !error,
    mutate,
  };
};

export default useGoogleBookOnline;
